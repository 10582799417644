import React from "react"
import styled from "@emotion/styled"
import { FaCircle } from "react-icons/fa"

import Project from "../../components/project"
import Image from "../../components/image"
import { color } from "../../utils"

const Container = styled.article`
  margin: 0;
  padding: 2rem;
  color: ${color.textBackgroundBlack};
  font-size: 1.1rem;
  line-height: 2rem;
  background-color: ${color.backgroundTransparencyBlack};
  opacity: 0;

  animation: fade 1.2s ease-in-out forwards;
  @keyframes fade {
    to {
      opacity: 1;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 3% 10%;
    padding: 2rem 4rem;
    font-size: 1.2rem;
    line-height: 2.5rem;
  }
`
const Title = styled.h1`
  &.title {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0;
    color: #ffffffa8;
    font-weight: 900;
    font-family: "MuseoModerno", cursive;
  }

  @media screen and (min-width: 768px) {
    &.title {
      font-size: 2rem;
      margin: 1rem 0 3rem 0;
    }
  }
`
const SubTitle = styled.h2`
  &.subtitle {
    font-family: "MuseoModerno", cursive;
    font-size: 1.4rem;
    color: #fb3c76;
  }
`
const TextMain = styled.p`
  text-justify: auto;
  font-size: 1.1rem;
  margin-bottom: 1.7rem;

  span {
    font-size: 1.2rem;
    color: #ff9db1;
  }

  a,
  a:visited {
    color: inherit;
    text-decoration: underline;
  }

  a:hover {
    color: #fb3c76;
  }

  @media screen and (min-width: 768px) {
  }
`
const ImageContainer = styled.div`
  display: grid;

  &.two {
    column-gap: 10px;
    grid-template-columns: 1fr;
  }

  &.one {
    figure {
      margin: 3% auto 5% auto;
    }
    img {
      max-height: 500px;
    }
  }

  figure {
    margin: 3% 3% 6% 3%;
  }

  img {
    object-fit: cover;
    margin: 0;
    padding: 0;
  }

  figcaption {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 500px) {
    &.three {
      row-gap: 0;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr auto;
    }

    &.lncrnas {
      row-gap: 0;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }

    .tablet {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }

  @media screen and (min-width: 1000px) {
    &.three {
      row-gap: 4rem;
      grid-template-columns: 4fr 4fr 6.5fr;
      grid-template-rows: 1fr;
    }

    &.lncrnas {
      grid-template-columns: 2fr 1.4fr;
    }

    .tablet {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .lncrna1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .lncrna2 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    .lncrna3 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }
`

const EvoDevo = () => {
  return (
    <Project title="Evo Devo">
      <Container>
        <Title className="title">Evo Devo web application</Title>

        <SubTitle className="subtitle">Type of product:</SubTitle>
        <TextMain>Web application for biological data visualization</TextMain>
        <SubTitle className="subtitle">Role:</SubTitle>
        <TextMain>
          Design and development of the application (fullstack)
        </TextMain>
        <SubTitle className="subtitle">Tech stack:</SubTitle>
        <TextMain>R, R Shiny, CSS, Git</TextMain>
        <SubTitle className="subtitle">Description of the project:</SubTitle>
        <TextMain>
          The main objective of this app -{" "}
          <a
            href="https://apps.kaessmannlab.org/evodevoapp/"
            target="_blank"
            rel="noreferrer"
          >
            Evo-devo mammalian organs app
          </a>{" "}
          - is allowing for an interactive visualization of research data
          regarding developmental profiles of genes for 7 organs across 6
          mammalian species and a bird.
        </TextMain>
        <ImageContainer className="one">
          <figure>
            <Image
              fileName="evodevo-desktop.png"
              alt="Desktop view of Evo Devo app"
            />
            <figcaption>
              1. This application generates a group of graphs by fetching the
              search data, and manipulates it according to filtering parameters.
            </figcaption>
          </figure>
        </ImageContainer>
        <TextMain>
          Having a large set of biological data, and a basis for the type of
          visualization needed, I was asked to build an application that was
          interactive and easy to use. It was important for the app to be simple
          on the outside, since it was going to be used by people in other
          fields (medicine, for example).
        </TextMain>
        <TextMain>
          I used R to handle the data. The R language is widely used in
          computational biology as a way to develop algorithms or models in
          order to understand biological systems and relationships. From a small
          script that manually created some graphs, I built a larger script that
          handles all the manipulation of the data.
        </TextMain>

        <ImageContainer className="three">
          <figure>
            <Image
              fileName="evodevo-mobile-search.png"
              alt="Mobile view of Evo Devo's search form"
            />
            <figcaption>2.1. Search form in mobile.</figcaption>
          </figure>
          <figure>
            <Image
              fileName="evodevo-mobile-graphs.png"
              alt="Mobile view of Evo Devo's generated graphs"
            />
            <figcaption>2.2. Graphs in mobile.</figcaption>
          </figure>
          <figure className="tablet">
            <Image
              fileName="evodevo-tablet.png"
              alt="Tablet view of Evo Devo app"
            />
            <figcaption>2.3. View for tablets.</figcaption>
          </figure>
        </ImageContainer>
        <TextMain>
          For the frontend of the project (or the user interface), I used R
          Shiny, which is an R package designed to build apps in this language.
          It was a bit tricky to override some unintended behaviour. I also used
          CSS to apply the UI design.
        </TextMain>

        <TextMain>
          There's also a companion application -{" "}
          <a
            href="https://apps.kaessmannlab.org/lncRNA_app/"
            target="_blank"
            rel="noreferrer"
          >
            Evo-devo mammalian lncRNAs app
          </a>{" "}
          - built partially with scripts taken from this app, using the same
          data source. I helped with the frontend, keeping the design consistent
          throughout both apps, but maintaing each one's different
          "personalities".
        </TextMain>
        <ImageContainer className="lncrnas">
          <figure className="lncrna1">
            <Image
              fileName="lncrnas-desktop-1.png"
              alt="Desktop view of lncRNAs app"
            />
            <figcaption>3.1. Desktop view of lncRNAs app.</figcaption>
          </figure>
          <figure className="lncrna2">
            <Image
              fileName="lncrnas-desktop-2.png"
              alt="Data visualization in lncRNAs app"
            />
            <figcaption>3.2. Data in genome browser.</figcaption>
          </figure>
          <figure className="lncrna3">
            <Image
              fileName="lncrnas-mobile.png"
              alt="Mobile view of lncRNAs app"
            />
            <figcaption>3.3. Mobile view of lncRNAs app.</figcaption>
          </figure>
        </ImageContainer>
        <TextMain>Version control was done using Git (locally).</TextMain>
        <SubTitle className="subtitle">Design:</SubTitle>
        <TextMain>
          - <span>Layout:</span> The app has a simple layout: a main area, that
          shows the data, and a sidebar that has the forms regarding
          search/filter options. From the start, we knew that the main use for
          the app would be in a research and work related environment, therefore
          making desktop/tablet sizes a priority. However, it is responsive and
          mobile ready.{" "}
        </TextMain>
        <TextMain>
          - <span>Colors:</span> I kept the colors already being used in the
          manually created graphs (
          <FaCircle
            style={{
              color: "#3399CC",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #3399CC,{" "}
          <FaCircle
            style={{
              color: "#33CCFF",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #33CCFF,{" "}
          <FaCircle
            style={{
              color: "#CC0000",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #CC0000,{" "}
          <FaCircle
            style={{
              color: "#CC9900",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #CC9900,{" "}
          <FaCircle
            style={{
              color: "#339900",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #339900,{" "}
          <FaCircle
            style={{
              color: "#CC3399",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #CC3399,{" "}
          <FaCircle
            style={{
              color: "#FF6600",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #FF6600), and used white (
          <FaCircle
            style={{ color: "#FFF", fontSize: "0.7rem", margin: "0 4px 0 2px" }}
          />
          #FFF) and gray (
          <FaCircle
            style={{ color: "#333", fontSize: "0.7rem", margin: "0 4px 0 2px" }}
          />
          #333,{" "}
          <FaCircle
            style={{ color: "#555", fontSize: "0.7rem", margin: "0 4px 0 2px" }}
          />
          #555) throughout the rest of the app, in order to keep it as clean as
          possible.
        </TextMain>
        <TextMain>
          - <span>Typography:</span> I used the typeface Quicksand, with a font
          weight of 500, since it's elegant and simple, as well as easy to read.{" "}
        </TextMain>
      </Container>
    </Project>
  )
}

export default EvoDevo
